import Vue from 'vue'
import VueRouter from 'vue-router'
import BaiduMap from 'vue-baidu-map'
import cookies from 'vue-cookies'
import {
  Dialog
} from 'vant';

Vue.use(Dialog);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'qKqHKKs4aOoMeIYQm5fVmnuM7Zeuznrg'
})
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index/index.vue'),
    meta: {
      title: "惠医保登录页",
      keepAlive: false
  
    }
  },{
  path:'/password',
  name:'Password',
  component:()=> import('../views/index/password.vue'),
  meta:{
    title:'密码',
    keepAlive:false
  }
},{
  path:'/register',
  name:'Register',
  component:()=>import('../views/index/register.vue'),
  meta:{
    title:'惠医保注册页',
    keepAlive:false
  }
} ,{
  path:'/editPhone',
  name:'EditPhone',
  component:()=>import('../views/index/editPhone.vue'),
  meta:{
    title:'修改手机号码',
    keepAlive:false
  }
} , {
  path:'/home',
  name:'Home',
  component:()=>import ('../views/home/index.vue'),
  meta:{
    title:'惠医保首页',
    keepAlive:false
  }
},{
  path: '/qrcode',
  name: 'qrcode',
  component: () => import('../views/qrcode/qrcode.vue'),
  meta: {
    title: "惠医保直付码",
    keepAlive: false
  }
}, {
  path: '/cancel',
  name: 'cancel',
  component: () => import('../views/cancel/cancel.vue'),
  meta: {
    title: "惠医保直付成功",
    keepAlive: false
  }
}, {
  path: '/store',
  name: 'store',
  component: () => import('../views/store/store.vue'),
  meta: {
    title: "惠医保适用门店",
    keepAlive: true
  }
}, {
  path: '/record_cancel',
  name: 'record_cancel',
  component: () => import('../views/cancel/record_cancel.vue'),
  meta: {
    title: "惠医保直付记录",
    keepAlive: false
  },
}, {
  path: '/detail',
  name: 'detail',
  component: () => import('../views/cancel/detail.vue'),
  meta: {
    title: "惠医保直付详情",
    keepAlive: false
  },
},  {
  path: '/map',
  name: 'map',
  component: () => import('../views/store/map.vue'),
  meta: {
    title: "门店地图",
    keepAlive: false
  },
},  {
  path: '/fail',
  name: 'fail',
  component: () => import('../views/cancel/fail.vue'),
  meta: {
    title: '直付失败',
    keepAlive: false
  }
},{
  path:'/warranty_list',
  name:'Warranty_list',
  component:()=> import('../views/warranty/list.vue'),
  meta:{
    title:'卡号列表',
    keepAlive:false
  }
},{
  path:'/warranty_manage',
  name:'Warranty_manage',
  component:()=> import('../views/warranty/manage.vue'),
  meta:{
    title:'卡片列表',
    keepAlive:false
  }
},{
  path:'/warranty_detail',
  name:'Warranty_detail',
  component:()=> import('../views/warranty/detail.vue'),
  meta:{
    title:'卡片详情',
    keepAlive:false
  }
},{
  path:'/mine',
  name:'Mine',
  component:()=> import('../views/mine/index.vue'),
  meta:{
    title:'我的',
    keepAlive:false
  }
},{
  path:'/serve',
  name:'Serve',
  component:()=>import ('../views/text/serve.vue'),
  meta:{
    title:'服务条款',
    keepAlive:false
  }
},{
  path:'/privacy',
  name:'Privacy',
  component:()=>import ('../views/text/privacy.vue'),
  meta:{
    title:'隐私政策',
    keepAlive:false
  }
},
{
  path:'/instructions',
  name:'Instructions',
  component:()=>import ('../views/text/instructions.vue'),
  meta:{
    title:'使用说明',
    keepAlive:false
  }
}
]

const router = new VueRouter({
  routes,
})

// document.title=to.meta.title,
// 获取存储的token的值有的话赋值isLogin为true否则false
// varisLogin=cookies.get("token")?true:false;
router.beforeEach((to, from, next) => {
  if (cookies.get("token")) {
    if (to.path == '/') {
      next('/home');
    } else if( to.path == '/password'&& to.query.type == 'register'){
      next('/home');
    } else if( to.path == '/register' && to.query.type != 'setPwd'){
      next('/home');
    } else {
      next()
      // document.title = to.meta.title
    }
  } else {
    // if (to.path == '/' || to.path == '/login'  || to.path == '/register') {
      next();
    //   document.title = to.meta.title
    // } else {
    //   Dialog.alert({
    //     message: '登录过期，请重新登录',
    //   }).then(() => {
    //     localStorage.clear();
    //     cookies.remove("token") &&
    //       cookies.remove("uid") &&
    //       cookies.remove("lat") &&
    //       cookies.remove("lng");
    //     next('/');
    //     document.title = to.meta.title
    //   });

    // }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
export default router