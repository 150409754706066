<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  mounted(){
    this.$store.dispatch('getPermission');
  }
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
}
body{
  min-height: 100vh;
  background: #f9f9f9;
}
.van-dialog__confirm, .van-dialog__confirm:active{
  color: #1a9ff2;
}
</style>
