import Vue from 'vue'
import Vuex from 'vuex'
import {getDict} from '@/api/_api/api'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    verobj:{},//用户登录手机号，姓名，验证码；
    user_data:{},//用户登录token，id，姓名手机号
    dict:[],//字典组
    card:{},//证件类型号码
    login:{},//登录所需信息
    
  },
  mutations: {
    USER_INFO(state,obj){
      state.verobj = obj
    },  
    USER_DATA(state,data){
      state.user_data = data
    },
    SET_DICT(state,data){
      state.dict = data
    },
    SET_CARD(state,data){
      state.card = data
    },
    SET_LOGIN(state,data){
      state.login = data
    }
  },
  actions: {
    getPermission({commit}){
      return new Promise((resolve)=>{
        getDict().then(res =>{
          if(res.code == 200){
            commit('SET_DICT',res.data)
            resolve(res.data)
          }else{
            console.warn('字典组加载失败')
          }
        })
      })
    },
  },
  modules: {
  }
})
