<template>
  <div class="top">
    <van-nav-bar :title="title" @click-right="onClickRight" left-arrow @click-left="onClickLeft" >
      <template slot="right" v-if="right_btn">
        {{right_btn}}
      </template>
    </van-nav-bar>
    <!-- <div class="top_height"></div> -->
  </div>
</template>

<script>
export default {
  props: ["title",'ishave','right_btn'],
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight(){
      this.$emit('onClickRightBtn')
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-hairline--bottom:after{
  border: none ;
}
.top{
  i{
    color: white;
    font-size: vw(35);
  }
  
}
.top_height{
  height: vw(90);
}
/deep/.van-nav-bar__right{
    color: #fff;
  }
</style>
