import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { RadioGroup, Radio } from 'vant';
import { Button } from 'vant';
import { Dialog } from 'vant';
import { NavBar } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Toast } from 'vant';
import cookies from 'vue-cookies'
import { Popup } from 'vant';
import { DatetimePicker } from 'vant';
import { List } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { PullRefresh } from 'vant';
import Top from './components/top.vue';
import { Icon } from 'vant';
import clipboard from 'clipboard';
//注册到vue原型上
Vue.prototype.clipboard = clipboard;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
Vue.component('top',Top)
import './assets/font/iconfont'
// import VConsole from 'vconsole';
// Vue.prototype.$vconsole = new VConsole();
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon);
Vue.use(List);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.prototype.$cookies = cookies; 
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(PullRefresh)
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.config.productionTip = false

Vue.config.ignoredElements = [ 
  'wx-open-launch-app', 
]
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
