import axios from 'axios';
import {Toast} from 'vant'
import cookies from 'vue-cookies'
import router from '../router'

const _axiosapi = new axios.create({
    baseURL: '/',
    timeout: 60000 // 请求超时时间
});
axios.defaults.withCredentials = true;

_axiosapi.interceptors.request.use(request=>{
    if(cookies.get("token")){
        request.headers.Authorization = `Bearer${cookies.get("token")}`
    }
    return request;
},error => {
    console.log(error)
})

_axiosapi.interceptors.response.use(response => {
    const res = response.data;
    if (response.status === 200) {
        if(res.code == 401){
            cookies.remove('token')
            setTimeout(() => {
                router.push({path:'/'})
                Toast.fail('登录超时，请重新登录');
            }, 1500);
            return res
        }
        if(res.code != 200){
            setTimeout(() => {
                Toast(res.message || res.msg)
            }, 1500);
        }
        return res
    } else {
        Toast('网络错误，请稍后重试')
        return Promise.reject(res);
    }
}, error => {
    Toast('网络错误，请稍后重试')
    return Promise.reject(error);
});

export {  _axiosapi }; 
